<template>
  <div class="branch-selector-form">
    <h1 style="margin-top: 0">Escolha uma filial</h1>
    <el-autocomplete
      v-model="branch.name"
      :fetch-suggestions="queryBranch"
      clearable
      value-key="name"
      label="name"
      @select="handleSelect" />
    <el-button
      type="secondary"
      round
      @click="$emit('cancel')" >
      Escolher loja
    </el-button>
    <el-button
      class="button"
      type="primary"
      round
      @click="onBranchSubmit(branch.id, keepChoice)">
      Continuar
    </el-button>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  props: {
    onBranchSubmit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      keepChoice: false,
      branch: {
        name: "",
        id: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      branches: types.BRANCH_LIST,
      user: types.AUTH_USER,
      storeID: types.AUTH_ACTIVE_STORE,
    }),
    branchFuse() {
      return new Fuse(this.branches, {
        keys: [
          {
            name: "name",
            weight: 0.75
          },
          {
            name: "cnpj",
            weight: 0.125
          },
          {
            name: "phone",
            weight: 0.125
          }
        ],
        threshold: 0.15,
        tokenize: true
      });
    }
  },
  async mounted() {
    try{
    await this.$store.dispatch(types.BRANCH_LIST, {
      storeID: this.storeID,
      userID: this.user.id,
    })
    } catch(error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.branch = {
      name: "Matriz",
      ...this.branches[0]
    };
  },
  methods: {
    queryBranch(str, cb) {
      const branches = this.branches;
      const results = str ? this.branchFuse.search(str) : branches;
      cb(results);
    },
    handleSelect(item) {
      this.branch = { ...item };
    }
  }
};
</script>

<style scoped lang="scss">
header {
  text-align: center;
}
.branch-selector-form {
  position: relative;
  width: 100%;
  text-align: center;
  max-width: 500px;
  padding: 16px;
  background: #fafafa;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.34);
  .el-autocomplete {
    width: 100%;
    margin-bottom: 1rem;
  }
  .remember {
    margin-bottom: 0.75rem;
    margin-top: -0.5rem;
  }
}
</style>
