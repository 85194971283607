<template>
  <login-container />
</template>

<script>
import LoginContainer from "@/containers/Login";

export default {
  components: {
    LoginContainer
  }
};
</script>
