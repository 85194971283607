<template>
  <div class="forgot-password-form">
    <header>
      <h2>Trocar senha</h2>
    </header>
    <template v-if="step == 'identify'">
      <el-input
        v-mask="'###.###.###-##'"
        ref="cpf"
        v-model="cpf"
        autofocus
        clearable
        placeholder="Digite o seu CPF"
        @keyup.native.enter="handleConfirm"
      />
      <vue-recaptcha
        v-if="!captched"
        ref="recaptcha"
        :sitekey="$captcha"
        style="margin: 1rem auto 0;"
        @verify="handleCaptchaVerify"
        @expired="handleCaptchaExpire"
      />
      <el-button
        v-else
        :loading="searching"
        style="width: 100%; margin-top: 1rem;"
        class="button"
        type="primary"
        icon="el-icon-check"
        round
        @click="handleConfirm"
      >Confirmar</el-button>
    </template>
    <template v-if="step == 'token'">
      <template v-if="inApp">
        <p
          class="disclaimer"
        >Enviamos um código via sms para o seu celular. Você também pode ver esse código no app.</p>
        <br />

        <el-input
          v-mask="'######'"
          v-model="token"
          autofocus
          placeholder="TOKEN"
          class="token"
          @keyup.enter.native="confirmToken"
        >
          <el-button
            slot="append"
            :loading="searching"
            :disabled="token.length !== 6 || searching"
            @click="confirmToken"
          >Confirmar</el-button>
        </el-input>
        <div v-if="!allowResend" class="token-timer">
          <div
            :style="{
              width: ((countDown / 300) * 100).toFixed(2) + '%'
            }"
            class="bar"
          />
          <span>{{ countDown }}s</span>
        </div>
        <div v-else>
          <el-button
            :loading="searching"
            style="width: 100%; margin-top: 1rem;"
            class="button"
            type="primary"
            icon="el-icon-check"
            round
            @click="sendSMS"
          >Reenviar SMS</el-button>
        </div>
      </template>
      <template v-else>
        <p class="not-in-app">
          Este usuário precisa estar registrado no App para receber o token de confirmação e trocar a senha.
          <br />Entre em contato com
          <a href="mailto:meajuda@neopag.com">meajuda@neopag.com</a> ou instale nosso aplicativo:
        </p>
        <div class="app-stores">
          <a
            href="https://play.google.com/store/apps/details?id=com.neopag_mobile_customer&amp;hl=pt_BR"
            target="_blank"
          >
            <img
              src="https://uploads-ssl.webflow.com/5a301d62c197b800013c3c71/5ab251894ccef6f63548b9b8_GOOGLE-PLAY%20200px-%20.png"
              width="110"
            />
          </a>
          <a
            href="https://itunes.apple.com/br/app/neopag/id1358713766?l=en&amp;mt=8"
            target="_blank"
          >
            <img
              src="https://uploads-ssl.webflow.com/5a301d62c197b800013c3c71/5afcab272e56d5bba5be6706_disponivel-na-app-store-botao.png"
              width="122"
            />
          </a>
        </div>
      </template>
    </template>
    <template v-if="step == 'change'">
      <el-form
        ref="changePasswordForm"
        :rules="changePasswordFormRules"
        :model="changePasswordForm"
      >
        <el-form-item prop="pwd" label="Nova senha">
          <el-input
            v-model="changePasswordForm.pwd"
            type="password"
            @keyup.native.enter="handleChangePassword"
          />
        </el-form-item>
        <el-form-item prop="pwd2" label="Confirmar nova senha">
          <el-input
            v-model="changePasswordForm.pwd2"
            type="password"
            @keyup.native.enter="handleChangePassword"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="searching"
            style="width: 100%; margin-top: 1rem;"
            class="button"
            icon="el-icon-warning"
            round
            type="danger"
            @click="handleChangePassword"
          >Trocar senha</el-button>
        </el-form-item>
      </el-form>
    </template>

    <el-button
      :loading="searching"
      style="width: 100%; margin-top: 1rem; margin-left: 0;"
      class="button"
      type="secondary"
      icon="el-icon-arrow-left"
      round
      @click="$emit('cancel')"
    >Voltar</el-button>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  components: {
    VueRecaptcha
  },
  data() {
    const validatePwd = (rule, value, cb) => {
      if (value === "" && !value) {
        return cb(new Error("Digite a nova senha!"));
      } else if (value && value.length < 8) {
        return cb(new Error("Mínimo de 8 caractéres!"));
      }
      return cb();
    };
    const validatePwd2 = (rule, value, cb) => {
      if (value === "") {
        return cb(new Error("Confirme a nova senha!"));
      } else if (value !== this.changePasswordForm.pwd) {
        return cb(new Error("Senhas não coincidem"));
      }
      return cb();
    };
    return {
      step: "identify",
      cpf: "",
      email: "",
      token: "",
      inApp: false,
      countDown: 300,
      searching: false,
      userID: undefined,
      allowResend: true,
      captched: false,
      changePasswordForm: {
        pwd: "",
        pwd2: ""
      },
      changePasswordFormRules: {
        pwd: [
          {
            validator: validatePwd,
            trigger: "blur"
          }
        ],
        pwd2: [
          {
            validator: validatePwd2,
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters({
      bearer: types.AUTH_OPEN_BEARER
    }),
    validPwd() {
      return (
        this.changePasswordForm.pwd === this.changePasswordForm.pwd2 &&
        this.changePasswordForm.pwd.length >= 8
      );
    }
  },
  watch: {
    cpf(val) {
      if (val) this.email = "";
    },
    email(val) {
      if (val) this.cpf = "";
    }
  },
  async mounted() {
    setInterval(this.timer, 1000);
    try {
      await this.$store.dispatch(types.AUTH_OPEN_BEARER).catch(error => {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      });
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    handleConfirm() {
      if (this.email.length) {
        return this.handleEmailSearch();
      } else if (this.cpf.length) {
        return this.handleCPFSearch();
      }
    },
    timer() {
      const epoch = Math.round(new Date().getTime() / 1000.0);
      this.countDown = 300 - (epoch % 300);
      if (this.countDown === 1) {
        this.allowResend = true;
      }
    },
    async sendSMS() {
      try {
        await this.$store.dispatch(types.STORE_SEND_SMS, {
          userID: this.userID,
          bearer: this.bearer
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.allowResend = false;
    },
    async handleCPFSearch() {
      this.searching = true;
      try {
        const response = await this.$store.dispatch(
          types.CLIENT_PUBLIC_DETAILS,
          {
            cpf: this.cpf.replace(/\D/gi, "")
          }
        );
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.searching = false;
      if (response) {
        this.handleResponse(response);
      }
    },
    async handleEmailSearch() {
      this.searching = true;
      try {
        const response = await this.$store.dispatch(
          types.CLIENT_PUBLIC_DETAILS,
          {
            email: this.email
          }
        );
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.searching = false;
      if (response) {
        this.handleResponse(response);
      }
    },
    handleResponse(response) {
      this.inApp = response?.data?.registered_in_app;
      this.userID = response?.data?.id;
      if (this.inApp) {
        this.sendSMS();
      }
      this.step = "token";

      this.$nextTick(() => {
        this.token = "";
      });
    },
    handleChangePassword() {
      this.$refs.changePasswordForm.validate(valid => {
        if (valid) {
          this.changePassword();
        }
      });
    },
    handleCaptchaExpire() {
      this.$refs.recaptcha.reset();
    },
    async handleCaptchaVerify(response) {
      try {
        await this.$store
          .dispatch(types.AUTH_VERIFY_CAPTCHA, response)
          .then(() => {
            this.captched = true;
          })
          .catch(error => {
            if (this.$raven && error.status === 500) {
              this.$raven.captureException(error.message, { req: error });
            }
            this.$notify.error({ title: error.message });
          });
      } catch {
        this.$notify.error({
          message: "Ops, erro no captcha. Tente novamente"
        });
        this.$refs.recaptcha.reset();
        this.captched = false;
      }
    },
    async changePassword() {
      this.searching = true;
      try {
        await this.$store.dispatch(types.CLIENT_UPDATE, {
          user: {
            password: this.changePasswordForm.pwd,
            id: this.userID
          },
          customHeader: this.bearer
        });
        this.$notify.success({
          message: "Senha alterada com sucesso!"
        });
        this.searching = false;
        this.$emit("cancel");
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.searching = false;
    },
    async confirmToken() {
      this.searching = true;
      try {
        await this.$store.dispatch(types.CLIENT_TOKEN, {
          token: this.token,
          userID: this.userID,
          bearer: this.bearer
        });
        this.step = "change";
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        } else if (error.status === 404) {
          this.$notify.error("Token Inválido");
        } else {
          this.$notify.error({ title: error.message });
        }
      }
      this.searching = false;
    }
  }
};
</script>

<style scoped lang="scss">
header {
  text-align: center;
  margin-bottom: 1rem;
}
.forgot-password-form {
  position: relative;
  width: 100%;
  max-width: 335px;
  padding: 16px;
  background: #fafafa;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.34);

  .disclaimer {
    font-family: $title-font;
    color: $preto;
  }

  .token-timer {
    display: flex;
    flex-flow: row wrap;
    width: 100px;
    height: 30px;
    margin: 1rem auto 0;
    text-align: center;
    span {
      flex: 0 100%;
      height: 20px;
      font-size: 11px;
      line-height: 20px;
    }
    .bar {
      position: relative;
      min-width: 1px;
      height: 8px;
      // background: rgba($preto, 0.25);
      background: #0cf9cd;
      border-radius: 2px;
      &::before {
        position: absolute;
        top: -1px;
        left: -1px;
        width: 102px;
        min-width: 1px;
        height: 10px;
        content: "";
        border: 1px solid $preto;
        border-radius: 2px;
      }
    }
  }
  .not-in-app {
    font-family: $title-font;
    line-height: 1.55;
    font-size: 15px;
    color: $preto;
  }
  .app-stores {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
}
</style>
