<template>
  <div class="storeless-suggestion">
    <header>
      <h2>Cadastre sua loja!</h2>
    </header>
    <p>
      Identificamos sua conta na nossa base mas parece que você ainda não
      cadastrou nenhuma loja.
    </p>
    <br />
    <el-button type="primary" @click="handleOnboardingNavigation">
      Cadastrar minha primeira loja!
    </el-button>
    <br /><br />
    <p>
      Caso queira verificar suas faturas como cliente acesse o App pelo seu
      celular!
    </p>
    <div class="app-stores">
      <a
        href="https://play.google.com/store/apps/details?id=com.neopag_mobile_customer&amp;hl=pt_BR"
        target="_blank"
      >
        <img
          src="https://uploads-ssl.webflow.com/5a301d62c197b800013c3c71/5ab251894ccef6f63548b9b8_GOOGLE-PLAY%20200px-%20.png"
          width="110"
        />
      </a>
      <a
        href="https://itunes.apple.com/br/app/neopag/id1358713766?l=en&amp;mt=8"
        target="_blank"
      >
        <img
          src="https://uploads-ssl.webflow.com/5a301d62c197b800013c3c71/5afcab272e56d5bba5be6706_disponivel-na-app-store-botao.png"
          width="122"
        />
      </a>
    </div>

    <el-button
      style="width: 100%; margin-top: 1rem;"
      class="button"
      type="secondary"
      icon="el-icon-arrow-left"
      round
      @click="$emit('cancel')"
    >
      Voltar
    </el-button>
  </div>
</template>

<script>
export default {
  methods: {
    handleOnboardingNavigation() {
      this.$router.push("/signup");
    }
  }
};
</script>

<style scoped lang="scss">
header {
  text-align: center;
  margin-bottom: 1rem;
}
.storeless-suggestion {
  position: relative;
  width: 100%;
  max-width: 300px;
  padding: 16px;
  background: #fafafa;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.34);
  text-align: center;

  p {
    text-align: left;
    font-family: $title-font;
    line-height: 1.55;
    font-size: 15px;
    color: $preto;
  }
  .app-stores {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
}
</style>
