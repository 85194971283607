<template>
  <div class="store-selector-form">
    <h1 style="margin-top: 0">Escolha uma loja</h1>
    <el-autocomplete
      v-model="keyword"
      :fetch-suggestions="queryStore"
      clearable
      value-key="name"
      label="name"
      @select="handleSelect" />
    <el-button
      round
      type="secondary"
      icon="el-icon-back"
      @click="$emit('cancel')">
      Voltar
    </el-button>
    <el-button
      :disabled="!store"
      round
      type="primary"
      @click="onStoreSubmit(store.id)">
      Continuar
    </el-button>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  props: {
    onStoreSubmit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      keyword: '',
      store: {
        name: "",
        id: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      user: types.AUTH_USER
    }),
    stores() {
      return this.user.stores || [];
    },
    storeFuse() {
      return new Fuse(this.stores, {
        keys: [
          {
            name: "name",
            weight: 0.75
          },
          {
            name: "cnpj",
            weight: 0.005
          },
          {
            name: "email",
            weight: 0.125
          },
          {
            name: "phone",
            weight: 0.12
          }
        ],
        threshold: 0.15,
        tokenize: true
      });
    }
  },
  mounted() {
    if (Array.isArray(this.stores) && this.stores.length > 0) {
      this.store = { ...this.stores[0] };
      this.keyword = this.stores[0].name;
    }
  },
  methods: {
    queryStore(str, cb) {
      this.store = null;
      const stores = this.stores;
      const results = str ? this.storeFuse.search(str) : stores;
      cb(results);
    },
    handleSelect(item) {
      this.store = { ...item };
    }
  }
};
</script>

<style scoped lang="scss">
header {
  text-align: center;
}
.store-selector-form {
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 500px;
  padding: 16px;
  background: #fafafa;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.34);
  .el-autocomplete {
    width: 100%;
    margin-bottom: 1rem;
  }
}
</style>
