<template>
  <el-container class="login-container">
    <logo class="login-container__logo" />
    <logo-graphic class="login-container__graphic" />
    <span class="login-container__ver">v{{ version }}</span>
    <div class="login-container__views">
      <forgot-password-form v-if="step === 'pwd'" @cancel="step = 'login'" />
      <branch-selection-form
        v-else-if="step === 'branch'"
        :on-branch-submit="handleBranchSelection"
        @cancel="step = 'store'"
      />
      <store-selection-form
        v-else-if="step === 'store'"
        :on-store-submit="handleStoreSelection"
        @cancel="cancel"
      />
      <storeless-suggestion v-else-if="step === 'storeless'" @cancel="cancel" />
      <login-form
        v-else
        :is-submitting="submitting"
        @requestNewPassword="handlePasswordRequest"
        @submit="login"
      />
    </div>
  </el-container>
</template>

<script>
import { version } from "@/../package.json";
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import LoginForm from "@/components/Auth/LoginForm";
import StoreSelectionForm from "@/components/Auth/StoreSelectionForm";
import BranchSelectionForm from "@/components/Auth/BranchSelectionForm";
import ForgotPasswordForm from "@/components/Auth/ForgotPasswordForm";
import StorelessSuggestion from "@/components/Auth/StorelessSuggestion";
import Logo from "@/components/Logo";
import LogoGraphic from "@/components/LogoGraphic";
import LogRocket from 'logrocket';

export default {
  components: {
    LoginForm,
    ForgotPasswordForm,
    StoreSelectionForm,
    BranchSelectionForm,
    StorelessSuggestion,
    Logo,
    LogoGraphic
  },
  data() {
    return {
      step: "login",
      submitting: false,
      version
    };
  },
  computed: mapGetters({
    user: types.AUTH_USER,
    branches: types.BRANCH_LIST,
    signUpStep: types.AUTH_CAMES_FROM_SIGNUP,
    storeCreated: types.STORE_ALREADY_CREATED
  }),
  async mounted() {
    localStorage.clear();
    if (this.signUpStep) {
      this.step = this.signUpStep.step;
      this.handleStoreSelection(this.storeCreated.id);
    } else {
      try {
        await this.$store.dispatch(
          types.AUTH_SHOW_EMAIL_FEEDBACK,
          this.$route.query.email_confirmed
        );
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    }
  },
  methods: {
    login(data) {
      this.submitting = true;
      this.$store
        .dispatch(types.AUTH_LOGIN, data)
        .then(async resp => {
          const user = resp.data.data.user;

          if (window.location.host === 'store.neopag.com') {
            LogRocket.identify(user.id, {
              name: user.name,
              email: user.email,
            });
          }

          if (!user.stores || user.stores.length === 0) {
            this.$notify.warning({
              title: this.$t("login.error.storeless.title"),
              message: this.$t("login.error.storeless.message")
            });
            this.step = "storeless";
          } else {
            this.step = "store";
          }
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$store.dispatch(types.AUTH_LOGOUT);
          this.$notify.error({ title: error.message });
        })
        .finally(() => {
          this.submitting = false;
          return !this.$store.getters[types.AUTH];
        });
    },
    async handleStoreSelection(storeID) {
      const store = this.user.stores.find(it => it.id === storeID);
      try {
        await this.$store.dispatch(types.AUTH_ACTIVE_STORE, {
          storeID,
          userID: this.user.id
        });
        if (this.signUpStep) {
          await this.$store.dispatch(types.AUTH_ACTIVE_STORE_DETAILS, {
            cnpj: this.storeCreated.cnpj,
            email: this.storeCreated.email,
            id: this.storeCreated.id,
            name: this.storeCreated.name,
            permissions: {
              active: true,
              add_clients: true,
              cancel_payment: true,
              cancel_sale: true,
              change_first_installment_date: true,
              change_total_limit: true,
              charging: true,
              dashboard: true,
              deals: true,
              installment_discount: true,
              payments: true,
              reports: true,
              sale_if_address_not_aproved: true,
              sale_if_address_not_sent: true,
              sale_if_documents_not_aproved: true,
              sale_if_documents_not_sent: true,
              sale_if_income_not_aproved: true,
              sale_if_income_not_sent: true,
              sale_if_phone_not_confirmed: true,
              sales: true,
              settings: true,
              statement_discount: true
            },
            phone: this.storeCreated.phone,
            role: "owner"
          });
          await this.$store.dispatch(
            types.AUTH_ACTIVE_BRANCH,
            this.storeCreated.branches[0].id
          );
          await this.$store.dispatch(
            types.AUTH_ACTIVE_BRANCH_DETAILS,
            this.storeCreated.branches[0].id
          );
          this.$router.push(this.$route.params.redirect || "/");
          return;
        }
        await this.$store.dispatch(types.AUTH_ACTIVE_STORE_DETAILS, store);
        if (!this.branches || this.branches.length === 0) {
          await this.$store.dispatch(types.AUTH_ACTIVE_STORE, {
            storeID
          });
          const isMainBranch = this.branches.find(
            it => it.is_main_branch === true
          );
          await this.$store.dispatch(types.AUTH_ACTIVE_BRANCH, isMainBranch.id);
          this.$router.push(this.$route.params.redirect || "/");
        } else if (this.branches && this.branches.length === 1) {
          await this.$store.dispatch(
            types.AUTH_ACTIVE_BRANCH,
            this.branches[0].id
          );
          await this.$store.dispatch(
            types.AUTH_ACTIVE_BRANCH_DETAILS,
            this.branches[0]
          );
          this.$router.push(this.$route.params.redirect || "/");
        } else if (this.branches && this.branches.length > 0) {
          this.step = "branch";
        }
      } catch (error) {
        this.$notify.error({
          title: "Erro.",
          message:
            "Loja bloqueada. Entre em contato com a Neopag pelo e-mail meajuda@neopag.com para maiores informações."
        });
      }
    },
    async handleBranchSelection(branchID, keepChoice) {
      try {
        const branch = this.branches.find(it => it.id === branchID);
        await this.$store.dispatch(types.AUTH_ACTIVE_BRANCH, branchID);
        await this.$store.dispatch(types.AUTH_ACTIVE_BRANCH_DETAILS, branch);

        if (keepChoice) {
          await this.$store.dispatch(types.AUTH_ACTIVE_BRANCH_CHOICE, branch);
        }
        this.$router.push(this.$route.params.redirect || "/");
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    async cancel() {
      await this.$store.dispatch(types.AUTH_LOGOUT).catch(error => {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      });
      this.step = "login";
    },
    async handlePasswordRequest() {
      this.step = "pwd";
    }
  }
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: flex-start;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: #79838a;

  &__graphic {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(-25%, -9%);
    pointer-events: none;
  }

  &__ver {
    z-index: 2;
    position: fixed;
    left: 2rem;
    bottom: 2rem;
    font-family: $title-font;
    font-size: 0.6rem;
    color: #fff;
  }

  &__views {
    z-index: 3;
    flex: 0 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 2rem;
    @include large-up {
      margin-top: 12vh;
    }
  }

  &__logo {
    z-index: 3;
    flex: 0 100%;
    margin-top: 2rem;
  }
}
</style>
