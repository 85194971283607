<template>
  <el-form
    ref="loginForm"
    :model="loginForm"
    :rules="rules"
    class="login-form"
    @submit.prevent.native
  >
    <div>
      <h1>Já sou cadastrado</h1>
      <el-form-item label="Email ou CPF" prop="emailorcpf">
        <el-input
          ref="emailorcpf"
          v-model="loginForm.emailorcpf"
          auto-complete="email"
          autocomplete.native="email cpf CPF"
          placeholder="email@dom.com / 123.456.789-09"
          @keydown.enter.native="handleSubmit"
        />
      </el-form-item>
      <el-form-item :label="$t('login.labels.password')" prop="password">
        <el-input
          ref="password"
          v-model="loginForm.password"
          autocomplete.native="current-password"
          type="password"
          @keydown.enter.native="handleSubmit"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="isSubmitting"
          class="button"
          type="primary"
          round
          @click="handleSubmit"
        >{{ $t("login.labels.submit") }}</el-button>
        <div style="text-align: right;">
          <el-button type="text" @click="$emit('requestNewPassword')">Esqueci minha senha</el-button>
        </div>
      </el-form-item>
    </div>
    <div class="new-account">
      <el-form-item>
        <h1 style="font-weight: 700;">Ainda não tem conta?</h1>
        <h2 style="font-weight: 700;">
          Faça parte da rede
          <br />de confiança Neopag.
        </h2>
        <el-button
          style="font-weight: 700;"
          type="primary"
          round
          @click="handleOnboardingNavigation"
        >Cadastre sua loja agora</el-button>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
export default {
  props: {
    stores: {
      type: Array,
      default: () => []
    },
    isSubmitting: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      egg: new this.$egg(),
      loginForm: {
        emailorcpf: "",
        password: ""
      },
      rules: {
        emailorcpf: [
          {
            required: true,
            message: "Preencha o campo acima",
            trigger: "submit"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("login.rules.password"),
            trigger: "submit"
          }
        ]
      }
    };
  },
  computed: {
    computedMask() {
      const inputed = this.loginForm.emailorcpf + "";
      if (inputed.length === 11 && /^[0-9]*$/.test(inputed)) {
        return "###.###.###-##";
      }
      return "";
    }
  },
  created() {
    this.egg
      .addCode("esc,esc,up", () => {
        if (this.$isDev) {
          this.loginForm.emailorcpf = "owner.teste@neopag.com";
          this.loginForm.password = "123Staging";
        }
      })
      .listen();
  },
  mounted() {
    this.$refs.emailorcpf.focus();
  },
  methods: {
    handleOnboardingNavigation(event) {
      if (event) {
        event.preventDefault();
      }
      this.$router.push({
        name: "Cadastrar Loja",
        params: { camesFromLogin: true }
      });
    },
    async handleSubmit() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          const inputed = this.loginForm.emailorcpf + "";
          delete this.loginForm.emailorcpf;
          if (inputed.length === 11 && /^[0-9]*$/.test(inputed)) {
            this.loginForm.cpf = inputed;
          } else {
            this.loginForm.email = inputed;
          }
          this.$emit("submit", {
            ...this.loginForm
          });
          this.loginForm.emailorcpf = inputed;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
header {
  text-align: center;
}
.login-form {
  h1 {
    text-align: center;
    font-weight: 500;
  }

  position: relative;
  width: 100%;
  max-width: 980px;
  padding: 16px;
  background: #fafafa;
  border-radius: 5px;
  animation-name: login;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-direction: forwards;

  display: grid;
  grid-template-columns: 400px 1fr;
  grid-column-gap: 24px;

  @include box-shadow(1);

  .el-form-item:last-child {
    margin-bottom: 0;
  }

  .new-account {
    border-left: solid 1px $preto;
    .el-form-item {
      display: flex;
      height: 100%;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      h1 {
        margin: 0;
      }
      h2 {
        font-weight: 700;
        color: $laranja;
        line-height: 1.125;
        margin-bottom: 16px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 90%;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 24px;
    .new-account {
      border-left: none;
      border-top: solid 1px $preto;
      .el-form-item {
        margin-top: 14px;
      }
    }
  }
}
.button {
  width: 100%;
}

@keyframes login {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
